/* eslint-disable object-curly-newline */
import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { getInvoicePaymentsPaged, getInvoicePaymentAgents, getOnSitePaymentMethodsActiveByFacility } from '@api'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import fetchPaged from '@core/utils/fetchPaged'

export default function usePaymentList() {
  const { hasPermission } = usePermissions()
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const { configOrganization, configFacility } = useSelectOptions()

  const listTable = ref([])
  const totalListTable = ref(0)
  const tableColumns = computed(() => [
    // {
    //   text: '#ID',
    //   align: 'start',
    //   value: 'id',
    //   show: true,
    // },
    // { text: t('facilities.facility').toUpperCase(), value: 'facility', show: true },
    // { text: t('terminals.terminal_name').toUpperCase(), value: 'terminal_name', show: true },
    // {
    //   text: t('tooltip.actions').toUpperCase(),
    //   value: 'actions',
    //   align: 'center',
    //   sortable: false,
    //   show: hasPermission(97) || hasPermission(98),
    // },
    {
      text: '#ID',
      align: 'start',
      value: 'id',
      show: true,
    },
    { text: t('transactions.date').toUpperCase(), value: 'payment_date', show: true },
    { text: t('transactions.agent').toUpperCase(), value: 'user', show: true },
    { text: t('transactions.method').toUpperCase(), value: 'onsite_payment_method.name', show: true },
    { text: t('reservations.contact').toUpperCase(), value: 'guest_name', show: true },
    { text: 'TOTAL', value: 'amount', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const computedTotal = computed(() => listTable.value.reduce((a, b) => {
    if (b.status === 'S') return a + b.invoice_amount_without_fanaty_fee
    if (b.status === 'R') return a - b.invoice_amount_without_fanaty_fee

    return a
  }, 0))

  const searchQuery = ref('')
  const dateFrom = ref(null)
  const dateTo = ref(null)
  const statusFilter = ref(null)
  const agentFilter = ref(null)
  const mediumFilter = ref(null)
  const paymentMethodFilter = ref(null)
  const groupFilter = ref(null)
  const dateStr = ref([])

  const agentsOptions = ref([])
  const paymentMethodsOptions = ref([])
  const methodsOptions = ref([
    { text: 'Online', value: 'online' },
    { text: t('facilities.facility'), value: 'console' },
  ])

  const filters = ref(null)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: 100,

  })
  const descSort = ref([])
  const loading = ref(false)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const maxDate = ref(
    new Date(new Date()).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const minDate = ref(
    new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const dateNow = ref(new Date()
    .toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .substring(0, 10))

  const fetchAgents = async () => {
    agentsOptions.value = []
    const resp = await getInvoicePaymentAgents(configFacility.value)
    if (resp.ok) agentsOptions.value = resp.data
    agentsOptions.value.push({ username: 'online', fullname: 'Online' })

    // else agentsOptions.value = []
  }

  const fetchOnSitePaymentMethods = async () => {
    const resp = await getOnSitePaymentMethodsActiveByFacility(configFacility.value)
    if (resp.ok) paymentMethodsOptions.value = resp.data
    else paymentMethodsOptions.value = []
  }

  const fetchPagedPayments = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (statusFilter.value) filterOption.status = statusFilter.value
    if (mediumFilter.value) filterOption.payment_medium = mediumFilter.value
    if (paymentMethodFilter.value) filterOption.onsite_payment_method_id = paymentMethodFilter.value
    if (agentFilter.value) filterOption.user_id = agentFilter.value
    if (userData.value && userData.value.role !== 'A') {
      filterOption.group_id = userData.value.group_id
    } else if (configOrganization.value) {
      filterOption.group_id = configOrganization.value
    }
    if (configFacility.value) filterOption.facility_id = configFacility.value

    if (dateStr.value.length) {
      const [start, end] = dateStr.value
      filterOption.start_date = start
      filterOption.end_date = end
      filterOption.dateStr = dateStr.value
    }
    filters.value = filterOption

    // if (dateFrom.value) filterOption.start_date = dateFrom.value
    // if (dateTo.value) filterOption.end_date = dateTo.value
    const response = await fetchPaged(getInvoicePaymentsPaged, options.value, filterOption)

    listTable.value = response.data.payments
    totalListTable.value = response.data.total
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.status) statusFilter.value = value.status
      if (userData.value && userData.value.role !== 'A') {
        groupFilter.value = userData.value.group_id
      } else if (value.group_id) {
        groupFilter.value = value.group_id
      }
      if (value.start_date) dateFrom.value = value.start_date
      if (value.end_date) dateTo.value = value.end_date
      if (value.dateStr) dateStr.value = value.dateStr
      else {
        dateStr.value = [dateNow.value, dateNow.value]
      }
      if (value.payment_medium) mediumFilter.value = value.payment_medium
      if (value.onsite_payment_method_id) paymentMethodFilter.value = value.onsite_payment_method_id
      if (value.user_id) agentFilter.value = value.user_id
    } else {
      dateStr.value = [dateNow.value, dateNow.value]
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchPagedPayments()
    }, 1000)
  }

  watch([configFacility, configOrganization], async () => {
    searchQuery.value = ''
    statusFilter.value = null
    dateFrom.value = null
    dateTo.value = null
    groupFilter.value = null

    await fetchAgents()
    await fetchOnSitePaymentMethods()
  })

  watch([statusFilter, groupFilter, dateStr, dateFrom, dateTo, mediumFilter, paymentMethodFilter, agentFilter], async () => {
    // start loading
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await fetchPagedPayments()
  })

  watch([searchQuery, configOrganization, configFacility], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchPagedPayments()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClientAvatarVariant = status => {
    if (status === 'S') return 'success'
    if (status === 'R') return 'error'
    if (status === 'H') return 'warning'
    if (status === 'C' || status === 'D') return 'primary'

    return 'primary'
  }

  return {
    userData,
    listTable,
    computedTableColumns,
    totalListTable,
    computedTotal,
    searchQuery,
    statusFilter,
    agentFilter,
    mediumFilter,
    paymentMethodFilter,
    dateStr,
    groupFilter,
    dateFrom,
    dateTo,
    maxDate,
    minDate,
    hasOptionsFilter,
    flag,
    filters,
    options,
    descSort,
    loading,
    agentsOptions,
    methodsOptions,
    paymentMethodsOptions,

    // i18n
    t,

    fetchPagedPayments,
    fetchAgents,
    fetchOnSitePaymentMethods,
    resolveClientAvatarVariant,
    hasPermission,
    setFilters,
    setOptions,
  }
}
